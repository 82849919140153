.terms-base {
    display: flex;
    flex-direction: column;
}

.terms-body {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    width: 70vw;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
    text-align: justify;
}

.terms-title {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    /* font-weight: 400px; */
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
    .terms-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}