/* home.css */

:root {
    --white: #ffffff;
    --gray: #6c757d;
}


.hero-wrap {
    min-height: 90vh;
    background: var(--blue);
    color: var(--white);
    padding: 0 15px;
}

.pay-method-text:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 20px;
    left: 0;
    top: -1px;
    background-image: -webkit-linear-gradient( 90deg, rgb(233, 14, 14) 0%, rgb(10, 119, 50) 100%);
}

.pay-method-text {
    font-family: 'Rubik', sans-serif;
    position: relative;
    padding-left: 14px;
    margin-top: 70px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
}

.pay-tile img {
    height: 30px; 
    width: auto; 
    object-fit: cover; 
}

.pay-tile {
    cursor: pointer;
    /* margin-bottom: 30px; */
    box-shadow: 0px 8px 25px rgba(36, 36, 36, 0.08);
    min-height: 50px;
    max-width: 12.5% !important;
    padding: 5px;
    justify-content: center;
}

.whitebg{
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: start; 
}



.center-block {
    max-width: 80%; 
    height: auto;
}



.titleHome {
    font-size: 2.2em;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
}

.descriptionHome {
    font-size: 1.125em;
    line-height: 1.6;
    float: right;
}
.connecting-lives{
    padding-top: 20px;
    padding-left: 250px;
}
.download {
    display: flex;
    align-items: center;
}

.download-text {
    font-size: 1.125em;
}

.download-links {
    display: flex;
}

.appstore-img, .android-img {
    width: 150px;
    height: auto;
    margin: 0 1rem;
}

.phone-image {
    width: 80%;
    height: auto;
    object-fit: contain;
}



@keyframes textAnimation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.titleHome, .descriptionHome, .download {
    animation: textAnimation 1s ease-out forwards;
}

.phone-image {
    animation: imageAnimation 1s ease-out forwards;
}
.logoHome {
    width: 15rem;
    height: auto;
    animation: imageAnimation 1s ease-out forwards;
    float: right;
}



.titleHome {
    animation-delay: 0.5s;
}

.descriptionHome {
    animation-delay: 1s;
}

.download {
    animation-delay: 1.5s;
}

.imagecontainerHome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .reviews{
    width: 30%;
    justify-content: center;
    /* background-color: whitesmoke; */
    border-radius: 10px;
    
  }
  .reviews li {
    display: flex;
    align-items: center;
  }
  
  .reviews li::before {
    content: "\25C6";
    color: #FF5F00;
    display: inline-block; 
    width: 1em;
    margin-right: 0.5em; 
  }
  

  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerHome {
    /* position: absolute; */
    padding-top: 1.3rem;
    padding-bottom:  2rem;
    bottom: 0;
    width: 100%;
    height: 50px; 
    background-color: #1a1a1a;
    color: white; 
    display: flex;
    justify-content: center;
    align-items: center;
}

  
  .icon {
    color: white; 
    font-size: 1.5em;
    margin: 0 10px;
  }
  
  @media only screen and (max-width: 1200px) {
    .titleHome {
        font-size: 1.5em; 
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }
}

@media only screen and (max-width: 992px) {
    
 
    .titleHome {
        font-size: 1.2em; 
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }
    .phone-image {
        width: 50%; 
    }
    .driving-excellence{
        color: black;
    }

    .connecting-lives{
        color: black;
    }
    .titleHome {
        
        margin-top: 50px;
    }
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .reviews {
      flex-direction: row !important; 
    }
    
    .reviews li::before {
      content: ""; 
    }
  }

@media only screen and (max-width: 768px) {
    .titleHome {
        font-size: 1em;
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }

    .driving-excellence span,
    .connecting-lives span {
      display: none;
    }
    .driving-excellence::before {
        content: "Driving Excellence. Connecting Lives";
        font-size: 1.4em; 
        font-family: 'Rubik', sans-serif;
    }
    

    
    .descriptionHome {
      margin-top: 0;
    }
    

    .connecting-lives{
        color: black;
    }
    
    .download .download-links a{
        padding: 1rem ;
    }
    .phone-image {
        margin-top: 15px;
        width: 60%; 
    }
    .appstore-img, .android-img {
        margin: 1rem 0; 
    }
    .connecting-lives {
        padding-left: 0; 
        margin-bottom: -4rem;
    }
    .download {
        margin-top: 0;
        justify-content: stretch;
        align-content: end;
    }
    .descriptionHome {
        font-size: 0.8em; 
        color: black;
    }
    .pay-method-text {
        margin-top: 40px; 
    }
}

.toasty-style {
    background-color: #13a713;
    color: white;
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color: #df6f0f;
    border-top-color: #aa3f00;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}
