.contact-base {
    display: flex;
    width: 715px;
    height: auto;
    flex-direction: column;
    /* margin-top: 10vh;
    margin-bottom: 10vh; */
    margin: 5vh auto;
}

.contact-body {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
    height: auto;
    /* margin-top: 10vh; */
}

.contact-title {
    display: flex;
    /* justify-content: center; */
    font-size: 30px;
    font-weight: 400;
}

.contact-desc {
    display: flex;
    color: darkgray;
    margin-bottom: .5rem;
    /* justify-content: center; */
}

@media only screen and (max-width: 600px) {
    .contact-base {
        width: 100%;
        margin: 5vh auto;
    }
}