@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
/* home.css */

:root {
    --white: #ffffff;
    --gray: #6c757d;
}


.hero-wrap {
    min-height: 90vh;
    background: var(--blue);
    color: #ffffff;
    color: var(--white);
    padding: 0 15px;
}

.pay-method-text:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 20px;
    left: 0;
    top: -1px;
    background-image: -webkit-linear-gradient( 90deg, rgb(233, 14, 14) 0%, rgb(10, 119, 50) 100%);
}

.pay-method-text {
    font-family: 'Rubik', sans-serif;
    position: relative;
    padding-left: 14px;
    margin-top: 70px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
}

.pay-tile img {
    height: 30px; 
    width: auto; 
    object-fit: cover; 
}

.pay-tile {
    cursor: pointer;
    /* margin-bottom: 30px; */
    box-shadow: 0px 8px 25px rgba(36, 36, 36, 0.08);
    min-height: 50px;
    max-width: 12.5% !important;
    padding: 5px;
    justify-content: center;
}

.whitebg{
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: start; 
}



.center-block {
    max-width: 80%; 
    height: auto;
}



.titleHome {
    font-size: 2.2em;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
}

.descriptionHome {
    font-size: 1.125em;
    line-height: 1.6;
    float: right;
}
.connecting-lives{
    padding-top: 20px;
    padding-left: 250px;
}
.download {
    display: flex;
    align-items: center;
}

.download-text {
    font-size: 1.125em;
}

.download-links {
    display: flex;
}

.appstore-img, .android-img {
    width: 150px;
    height: auto;
    margin: 0 1rem;
}

.phone-image {
    width: 80%;
    height: auto;
    object-fit: contain;
}



@-webkit-keyframes textAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}



@keyframes textAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes imageAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.titleHome, .descriptionHome, .download {
    -webkit-animation: textAnimation 1s ease-out forwards;
            animation: textAnimation 1s ease-out forwards;
}

.phone-image {
    -webkit-animation: imageAnimation 1s ease-out forwards;
            animation: imageAnimation 1s ease-out forwards;
}
.logoHome {
    width: 15rem;
    height: auto;
    -webkit-animation: imageAnimation 1s ease-out forwards;
            animation: imageAnimation 1s ease-out forwards;
    float: right;
}



.titleHome {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}

.descriptionHome {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.download {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

.imagecontainerHome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .reviews{
    width: 30%;
    justify-content: center;
    /* background-color: whitesmoke; */
    border-radius: 10px;
    
  }
  .reviews li {
    display: flex;
    align-items: center;
  }
  
  .reviews li::before {
    content: "\25C6";
    color: #FF5F00;
    display: inline-block; 
    width: 1em;
    margin-right: 0.5em; 
  }
  

  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerHome {
    /* position: absolute; */
    padding-top: 1.3rem;
    padding-bottom:  2rem;
    bottom: 0;
    width: 100%;
    height: 50px; 
    background-color: #1a1a1a;
    color: white; 
    display: flex;
    justify-content: center;
    align-items: center;
}

  
  .icon {
    color: white; 
    font-size: 1.5em;
    margin: 0 10px;
  }
  
  @media only screen and (max-width: 1200px) {
    .titleHome {
        font-size: 1.5em; 
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }
}

@media only screen and (max-width: 992px) {
    
 
    .titleHome {
        font-size: 1.2em; 
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }
    .phone-image {
        width: 50%; 
    }
    .driving-excellence{
        color: black;
    }

    .connecting-lives{
        color: black;
    }
    .titleHome {
        
        margin-top: 50px;
    }
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .reviews {
      flex-direction: row !important; 
    }
    
    .reviews li::before {
      content: ""; 
    }
  }

@media only screen and (max-width: 768px) {
    .titleHome {
        font-size: 1em;
    }
    .logoHome {
        width: 15rem;
        height: auto;
    }

    .driving-excellence span,
    .connecting-lives span {
      display: none;
    }
    .driving-excellence::before {
        content: "Driving Excellence. Connecting Lives";
        font-size: 1.4em; 
        font-family: 'Rubik', sans-serif;
    }
    

    
    .descriptionHome {
      margin-top: 0;
    }
    

    .connecting-lives{
        color: black;
    }
    
    .download .download-links a{
        padding: 1rem ;
    }
    .phone-image {
        margin-top: 15px;
        width: 60%; 
    }
    .appstore-img, .android-img {
        margin: 1rem 0; 
    }
    .connecting-lives {
        padding-left: 0; 
        margin-bottom: -4rem;
    }
    .download {
        margin-top: 0;
        justify-content: stretch;
        align-content: end;
    }
    .descriptionHome {
        font-size: 0.8em; 
        color: black;
    }
    .pay-method-text {
        margin-top: 40px; 
    }
}

.toasty-style {
    background-color: #13a713;
    color: white;
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color: #df6f0f;
    border-top-color: #aa3f00;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.driverForm-container,
.DriverForm .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.DriverForm .driverForm-container {
    height: 100vh;
}

.DriverForm .user-info {
    margin-bottom: 20px;
}

.DriverForm  .user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.DriverForm .user-name,
.DriverForm .card-header {
    font-size: 20px;
    font-weight: bold;
}

.DriverForm .card-header {
    text-align: center;
}

.DriverForm .info-table {
    font-size: 18px;
}

.DriverForm .info-table td {
    padding: 10px;
}

.DriverForm .info-table tr:hover {
    background-color: #f8f9fa;
}

.DriverForm .app-alert {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    -webkit-animation: fadeIn 2s ease-in;
            animation: fadeIn 2s ease-in;
}

.DriverForm .app-card {
    width: 100%;
    max-width: 600px;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.DriverForm .app-card:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.DriverForm .sticker {
    position: absolute;
    font-size: 2rem;
    opacity: 0;
    -webkit-animation: clapping 3s linear infinite;
            animation: clapping 3s linear infinite;
}

@media (max-height: 400px) {
    .DriverForm .driverForm-container {
        margin-top: 120px;
        margin-bottom: 50px;
    }
    
    .DriverForm .app-card {
        max-height: 30vh;
        overflow: auto;
    }
}

@media (min-height: 401px) and (max-height: 600px) {
    .DriverForm .driverForm-container {
        margin-top: 80px;
        margin-bottom: 30px;
    }
    
    .DriverForm .app-card {
        max-height: 50vh;
        overflow: auto;
    }
}

@media (min-height: 601px) and (max-height: 800px) {
    .DriverForm .driverForm-container {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    
    .DriverForm .app-card {
        max-height: 70vh;
        overflow: auto;
    }
}

@media (min-height: 801px) {
    .DriverForm .driverForm-container {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    
    .DriverForm .app-card {
        max-height: 90vh;
        overflow: auto;
    }
}


@media (max-width: 600px) {
    .DriverForm .app-card {
        max-width: 100%;
        margin: 10px;
    }

    .DriverForm .app-alert {
        padding: 10px;
    }

    .DriverForm .user-image {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .DriverForm .app-card {
        max-width: 80%;
        margin: 20px;
    }
}

@media (min-width: 901px) {
    .DriverForm .app-card {
        max-width: 600px;
        margin: 30px;
    }
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@-webkit-keyframes clapping {
    0% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
    50% { -webkit-transform: translateY(-50vh); transform: translateY(-50vh); opacity: 1; }
    100% { -webkit-transform: translateY(-100vh); transform: translateY(-100vh); opacity: 0; }
}

@keyframes clapping {
    0% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
    50% { -webkit-transform: translateY(-50vh); transform: translateY(-50vh); opacity: 1; }
    100% { -webkit-transform: translateY(-100vh); transform: translateY(-100vh); opacity: 0; }
}

@media (min-width: 576px) {
    .modal.show .modal-dialog {
        max-width: 80%;
    }
}

.booking-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top-margin {
    margin-top: .9rem;
}

.book-form-with-map {
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: unset;
    margin: 20px 0 0;
    padding: 20px;
        
}

.map-container {
    margin: 1rem auto;
    height: 480px;
    width: 100%;
    background-color: grey;
    margin-left: 30px;
}

.pac-container {
    z-index: 1100 !important;
}

@media (max-width: 768px) {
    .book-form-with-map {
        display: initial;
    }
    .map-container {
        height: 400px;
        margin-left: 0px;
    }
}

.calendar-style {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.react-datepicker__input-container {
    width: calc(100% - 30px);
}

.calendar-style:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-datepicker__header {
    background-color: #ffffff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #1674e9 !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #1674e9 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #1674e9 !important;
}

input.calendar-style.invalid {
    border-color: #dc3545;
}

.select-trip-group {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
    margin-top: 10px;
}

.start-date-field {
    margin: 0 10px;
}

@media (max-width: 490px) {
    .select-trip-group {
        display: block;
    }

    .start-date-field, .end-date-field {
        margin: 10px 0;
        width: '100%';
        display: block;
    }
}

.app-container,
.user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-container {
    height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
}

.user-info {
    margin-bottom: 20px;
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.user-name,
.card-header {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
}

.card-header {
    text-align: center;
    color: #343a40;
}

.info-table {
    font-size: 18px;
}

.info-table td {
    padding: 10px;
}

.info-table tr:hover {
    background-color: #f8f9fa;
}

.app-alert {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    -webkit-animation: fadeIn 2s ease-in;
            animation: fadeIn 2s ease-in;
    margin-top: 100px;
}

.app-card {
    width: 100%;
    max-width: 600px;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.app-card:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.sticker {
    position: absolute;
    font-size: 2rem;
    opacity: 0;
    -webkit-animation: clapping 3s linear infinite;
            animation: clapping 3s linear infinite;
}
@media (max-width: 490px) {
    .app-container {
      padding-top: 300px; 
      padding-bottom: 230px;
    }

    .app-card-container{
        padding: 100px;
    }
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@-webkit-keyframes clapping {
    0% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
    50% { -webkit-transform: translateY(-50vh); transform: translateY(-50vh); opacity: 1; }
    100% { -webkit-transform: translateY(-100vh); transform: translateY(-100vh); opacity: 0; }
}

@keyframes clapping {
    0% { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
    50% { -webkit-transform: translateY(-50vh); transform: translateY(-50vh); opacity: 1; }
    100% { -webkit-transform: translateY(-100vh); transform: translateY(-100vh); opacity: 0; }
}
.payment-options {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .payment-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .payment-option.selected {
    border-color: green; 
    -webkit-transform: scale(1.05); 
            transform: scale(1.05); 
  }
  
  .payment-option h2 {
    margin-top: 10px;
  }


  .phone-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
  }
  
  .phone-input h2 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .phone-input input {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .phone-input .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .phone-input button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-grow: 1;
    margin: 0 10px;
  }
  
  .phone-input button:first-child {
    background-color: #f0ad4e;
  }
  
  .phone-input button:first-child:hover {
    background-color: #ec971f;
  }
  
  .phone-input button:last-child {
    background-color: #5cb85c;
  }
  
  .phone-input button:last-child:hover {
    background-color: #449d44;
  }
  
  
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    margin: 20px;
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #FF5F00;
    border-radius: 50%;
    -webkit-animation: spinner 1.5s linear infinite;
            animation: spinner 1.5s linear infinite;
  }
  
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .payment-card {
    position: relative;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .payment-card p {
    margin-top: 10px;
  }
  
.login-base {
    display: flex;
    background-color: rgb(255, 254, 250);
    justify-content: flex-start;
    flex-direction: column;
    width: 715px;
    height: auto;
    margin: 5vh auto;
}

.login-body {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: 100%;
    height: auto;
}

.login-search-form {
    display: flex;
    width: 70vw;
    height: 20vh;

}

.sub-header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: .6rem;
    color: darkgray;
}

@media only screen and (max-width: 600px) {
    .login-base {
        width: 100%;
        margin: 2.5vh auto;
    }
}

.admin-base {
    display: flex;
    background-color: rgb(255, 254, 250);
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: auto;
    margin: 0;
}

.admin-body {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: 100vw;
    /* height: 100vh; */
    height: auto;
    margin-top: 10vh;
}

.admin-search-form {
    margin-top: 2rem;
    display: flex;
    width: 70vw;
    height: auto;
    margin-bottom: 32px;
}

.sub-header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: .6rem;
    color: darkgray;
}

.linear-display {
    display: flex;
    padding: 5rem;
    flex-direction: column;
}

.btn-confirm {
    margin-bottom: 20px;
}

/* BookingsTab.css */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 20px 0;
  }
  
  .pagination-container button {
    border: none;
    background: #007BFF;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .pagination-container button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-container button:not(:disabled):hover {
    background: #0056b3;
  }
  
  .pagination-container input,
  .pagination-container select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

.driverImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
}
.custom-btn {
    background-color: #0056b3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.custom-btn:hover {
    background-color: #004292; 
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.availability-toggle {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.toggle-container {
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: #eaeaea;
  border-radius: 12px;
  position: relative;
}

.toggle-track {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 12px;
  position: absolute;
  transition: background-color 0.3s ease-in-out;
}

.toggle-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.toggle-thumb.active {
  -webkit-transform: translateX(16px);
          transform: translateX(16px);
}

.toggle-track.active {
  background-color: #66bb6a;
}

.toggle-icon {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}



@media (max-width: 768px) {
  

 
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .pagination-container button,
  .pagination-container span {
    margin: 0.5rem;
  }
}


.booking-modal {
    display: flex;
    flex-direction: column;
    /*background-color: #78a5d8;*/
}
.booking-modal-title {
    margin-bottom: 2.5rem;
}
.booking-modal-body {
    display: flex;
    justify-content: center;
    margin-top: -3.5rem;
}

.Modify-drivers-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -3.5rem;
}
.basic-card {
    width: 18rem;
    margin: 1rem 2.5rem 1rem;
    background-color:  rgb(255, 254, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bio-basic-card {
    width: 18rem;
    margin: 5rem 2.5rem 1rem;
    background-color:  rgb(255, 254, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.basic-card.card .card-img, .bio-basic-card .card-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

@media only screen and (max-width: 600px){
    .basic-card {
        width: 100%!important;
        margin-right: 5px!important;
        margin-left: 5px!important;
    }

    .basic-card.card .card-img, .bio-basic-card .card-img {
        width: 90px;
        height: 90px;
    }
    .card-title {
        width: 95%;
        font-size: 0.85rem!important;
        text-align: center!important;
    }
    .card-body {
        padding: 5px 0.85rem!important;
    }
}

.about-base {
    display: flex;
    flex-direction: column;
}

.about-body {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    width: 70vw;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
    text-align: justify;
}

.about-title {
    margin-bottom: 1.3rem;
    font-weight: 400;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .about-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}
.terms-base {
    display: flex;
    flex-direction: column;
}

.terms-body {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    width: 70vw;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
    text-align: justify;
}

.terms-title {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    /* font-weight: 400px; */
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
    .terms-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}
.contact-base {
    display: flex;
    width: 715px;
    height: auto;
    flex-direction: column;
    /* margin-top: 10vh;
    margin-bottom: 10vh; */
    margin: 5vh auto;
}

.contact-body {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
    height: auto;
    /* margin-top: 10vh; */
}

.contact-title {
    display: flex;
    /* justify-content: center; */
    font-size: 30px;
    font-weight: 400;
}

.contact-desc {
    display: flex;
    color: darkgray;
    margin-bottom: .5rem;
    /* justify-content: center; */
}

@media only screen and (max-width: 600px) {
    .contact-base {
        width: 100%;
        margin: 5vh auto;
    }
}
.profiles {
    padding-top: 2rem;
    margin: 0;
    height: auto;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: baseline;
}

@media only screen and (max-width: 600px) {
    .profiles {
        margin: 0;
        grid-template-columns: repeat(2, auto);
    }
}

.customer-img {
    width: 100%;
    object-fit: cover;
    height: 222px;
    margin: 20px 0;
}

.car-wrap {
    margin-bottom: 40px;
    box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.06);
    background: #fff;
}

.car-wrap .text {
    border-top: none;
    padding: 20px 30px 30px;
}

.customers-title {
    text-transform: capitalize;
    font-size: 50px;
    padding-bottom: 50px;
    margin-bottom: 1.3rem;
}

@media only screen and (max-width: 768px) {
    .customer-img {
        height: 180px;
    }
}

@media only screen and (max-width: 560px) {
    .customer-img {
        height: 120px;
    }
}
.privacy-base {
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: 18px;
}

.privacy-body h6 {
    font-size: 18px;
}

.privacy-body {
    display: flex;
    flex-direction: column;
    margin: '0 auto';
    margin-top: 10vh;
    width: 80%;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
}

.privacy-title {
    margin-bottom: 1.3rem;
    font-weight: 400;
    font-size: 30px;
}

@media only screen and (max-width: 960px) {
    .privacy-base {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .privacy-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}

.privacy-base ul li {
    list-style-type: none;
}

.privacy-base ul li, 
.privacy-base p {
    text-align: justify;
}
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    
  }
  
  .history-card {
    width: 70%;

    transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

    border-radius: 0.25rem;
    margin: 2rem;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .history-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  .history-card-body {
    display: flex;
    flex-direction: column;
  }
  
  .history-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .history-card-subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .history-card-text {
    display: flex;
    justify-content: space-between;
  }
  
  .history-card-paid {
    color: green;
  }
  
  .history-card-notpaid {
    color: red;
    font-weight: bold;
  }
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .trip-type, .price, .history-card-paid, .history-card-notpaid, .distance, .duration {
    margin: 5px;
    font-family: 'Urbanist', sans-serif;

  }
  .table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  font-family: 'Urbanist', sans-serif;

}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #757575;
  padding: 0.75rem;
  text-transform: uppercase;
  color: #bdbdbd;
}

.table tbody + tbody {
  border-top: 2px solid #757575;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-hover tbody tr:hover {
  color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.075);
}

.driver-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #FF5F00;
}

  
  .driver-name {
    font-weight: bold;
    color: darkslategrey;
  }
  
  .driver-category {
    color: darkslategrey;
  }
  
  
  .card-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: darkslategrey;
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 1.5rem;
  }
  
  .btn-secondary {
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    color: #1b1b1b;
    margin-top: 1.5rem;
  }

.delete-account-container {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
}

.delete-account-container p {
  text-align: justify;
  line-height: 2;
  font-size: 15px;
}

  .login-button {
    background-color: #008CBA; /* Blue */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 12px;
  }
  
  .login-button:hover {
    background-color: white;
    color: black;
  }
  
  
  .success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
  
  .success-popup p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .success-popup button {
    padding: 10px 20px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .success-popup button:hover {
    background-color: #0056b3;
  }
  
  .delete-account-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .delete-account-content {
    margin-bottom: 20px;
  }
  
  .delete-account-warning {
    margin-bottom: 10px;
  }
  
  .delete-account-checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .delete-account-checkbox {
    margin-right: 10px;
  }
  
  .delete-account-textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin-bottom: 10px;
  }
  
  .delete-account-button {
    padding: 10px 20px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-account-popup {
    margin-top: 20px;
  }
  
  .delete-account-popup-message {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .delete-account-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .delete-account-popup-button {
    padding: 8px 16px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-account-form {
    margin-top: 20px;
  }
  
  .delete-account-form-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .delete-account-form-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .delete-account-form-button {
    padding: 10px 20px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .delete-account-password-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .delete-account-password-popup-content {
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  }
  
  .delete-account-password-popup-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

/* .navbar-nav a  */
a.links.primaryColor,
a.links.whiteColor {
    padding: 0 20px;
    font-size: 18px;
    text-decoration: none;
}

.navbar-primary {
    /* background-color: #FF5F00; */
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header span {
    color: white;
    font-size: 1.3rem;
}

.header .logo {
    /* background: white; */
    width: 200px;
    /* padding: 5px 20px; */
}

.footer .logo {
    width: 300px;
}

@media only screen and (max-width: 600px) {
    .header {
        justify-content: flex-start;
        background-color:#FF5F00 ;
    }
}

.me-auto {
    font-size: 1rem;
    font-weight: 50;
    color: white;
}
.links {
    font-weight: bold;
    font-size: 15px;
}

.primaryColor, span.primaryColor {
    color: #FF5F00;
}

.dropdown-toggle::after {
    color: white;
}

.dropdown-toggle.primaryColor::after{
    color: #c7c3bc
}

.whiteColor {
    color: #FFFFFF;
}

.links:hover {
    color: #FF5F00;
}

.base-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.toggle {
    display: flex;
}

.footer {
    color: #ffffff;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    width: 100%;
    background-color: #191919;
    flex-direction: column;
    padding: 4em 0 2em;
    /* margin-top: 4em; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.footer li a {
    color: white;
    text-decoration: none;
    font-weight: normal;
    margin: 1.5rem 0;
    display: block;
}

.footer-copyright a {
    color: #ff5f00;
    text-decoration: none;
}

button.navbar-toggler.collapsed {
    background: white;
}

.ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;
}

.ftco-footer-social li a {
    height: 50px;
    width: 50px;
    display: block;
    float: left;
    background: rgb(89 89 89);
    border-radius: 50%;
    position: relative;
    color: #fff;
}

.ftco-footer-social li a svg {
    position: absolute;
    font-size: 26px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.avatarImg {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 5px;
}

.nav-dropdown-menu.dropdown-menu.show {
    right: 0px;
    margin: 0.75rem;
    top: 58px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
}

@media only screen and (max-width: 992px) {
    .header-container, .footer-container {
        padding: 0 20px;
        /* margin: 0; */
        width: 100%;
        max-width: 100%;
    }

    div#responsive-navbar-nav {
        background: white;
        border-radius: 4px;
    }

    a.links.primaryColor, a.links.whiteColor {
        padding: 5px 8px;
    }

    .whiteColor {
        color: #FF5F00;
    }

    .footer .logo {
        width: auto;
        max-width: 100%;
    }
}


@media only screen and (max-width: 768px) {
    .footer .logo {
        width: auto;
        max-width: 300px;
    }
}

@media only screen and (max-width: 480px) {
    .footer .logo {
        width: auto;
        max-width: 100%;
    }
}

body {
  min-height: 100vh; 
  margin:0;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: rgb(222, 227, 235); */
  background-color: rgb(255, 254, 250);
  overflow: hidden;
}

.profiles {
  display: flex;
  flex-flow: row wrap;
  margin-left: 3.4%;
  justify-content: center;
}

button.btn.btn-primary {
  background-color: #FF5F00;
  border-color: #FF5F00;
}

button.btn.btn-primary:hover, .btn-primary:after {
  background-color: #AA3F00;
  border-color: #AA3F00;
}

button.btn.btn-primary[disabled] {
  background-color: rgba(255, 95, 0, 0.42);
  border-color: rgba(255, 95, 0, 0.42);
}

button.btn.btn-secondary {
  background: lightgray;
  color: black;
  border-color: lightgrey;
}

a, a:hover{
  color: #1a7fc4;
}


@media only screen and (max-width: 600px) {
  .profiles {
    display: flex;
    justify-content: center;
    margin-left: 3.4%;
  }
}



