@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .spinner {
    margin: 20px;
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #FF5F00;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  