body {
  min-height: 100vh; 
  margin:0;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: rgb(222, 227, 235); */
  background-color: rgb(255, 254, 250);
  overflow: hidden;
}

.profiles {
  display: flex;
  flex-flow: row wrap;
  margin-left: 3.4%;
  justify-content: center;
}

button.btn.btn-primary {
  background-color: #FF5F00;
  border-color: #FF5F00;
}

button.btn.btn-primary:hover, .btn-primary:after {
  background-color: #AA3F00;
  border-color: #AA3F00;
}

button.btn.btn-primary[disabled] {
  background-color: rgba(255, 95, 0, 0.42);
  border-color: rgba(255, 95, 0, 0.42);
}

button.btn.btn-secondary {
  background: lightgray;
  color: black;
  border-color: lightgrey;
}

a, a:hover{
  color: #1a7fc4;
}


@media only screen and (max-width: 600px) {
  .profiles {
    display: flex;
    justify-content: center;
    margin-left: 3.4%;
  }
}

