.customer-img {
    width: 100%;
    object-fit: cover;
    height: 222px;
    margin: 20px 0;
}

.car-wrap {
    margin-bottom: 40px;
    -webkit-box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.06);
    background: #fff;
}

.car-wrap .text {
    border-top: none;
    padding: 20px 30px 30px;
}

.customers-title {
    text-transform: capitalize;
    font-size: 50px;
    padding-bottom: 50px;
    margin-bottom: 1.3rem;
}

@media only screen and (max-width: 768px) {
    .customer-img {
        height: 180px;
    }
}

@media only screen and (max-width: 560px) {
    .customer-img {
        height: 120px;
    }
}