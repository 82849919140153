.payment-options {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .payment-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .payment-option.selected {
    border-color: green; 
    transform: scale(1.05); 
  }
  
  .payment-option h2 {
    margin-top: 10px;
  }


  .phone-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
  }
  
  .phone-input h2 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .phone-input input {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .phone-input .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .phone-input button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-grow: 1;
    margin: 0 10px;
  }
  
  .phone-input button:first-child {
    background-color: #f0ad4e;
  }
  
  .phone-input button:first-child:hover {
    background-color: #ec971f;
  }
  
  .phone-input button:last-child {
    background-color: #5cb85c;
  }
  
  .phone-input button:last-child:hover {
    background-color: #449d44;
  }
  
  