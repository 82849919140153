.profiles {
    padding-top: 2rem;
    margin: 0;
    height: auto;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: baseline;
}

@media only screen and (max-width: 600px) {
    .profiles {
        margin: 0;
        grid-template-columns: repeat(2, auto);
    }
}
