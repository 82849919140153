.login-base {
    display: flex;
    background-color: rgb(255, 254, 250);
    justify-content: flex-start;
    flex-direction: column;
    width: 715px;
    height: auto;
    margin: 5vh auto;
}

.login-body {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: 100%;
    height: auto;
}

.login-search-form {
    display: flex;
    width: 70vw;
    height: 20vh;

}

.sub-header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: .6rem;
    color: darkgray;
}

@media only screen and (max-width: 600px) {
    .login-base {
        width: 100%;
        margin: 2.5vh auto;
    }
}
