@media (min-width: 576px) {
    .modal.show .modal-dialog {
        max-width: 80%;
    }
}

.booking-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top-margin {
    margin-top: .9rem;
}

.book-form-with-map {
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: unset;
    margin: 20px 0 0;
    padding: 20px;
        
}

.map-container {
    margin: 1rem auto;
    height: 480px;
    width: 100%;
    background-color: grey;
    margin-left: 30px;
}

.pac-container {
    z-index: 1100 !important;
}

@media (max-width: 768px) {
    .book-form-with-map {
        display: initial;
    }
    .map-container {
        height: 400px;
        margin-left: 0px;
    }
}

.calendar-style {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.react-datepicker__input-container {
    width: calc(100% - 30px);
}

.calendar-style:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-datepicker__header {
    background-color: #ffffff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #1674e9 !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #1674e9 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #1674e9 !important;
}

input.calendar-style.invalid {
    border-color: #dc3545;
}

.select-trip-group {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
    margin-top: 10px;
}

.start-date-field {
    margin: 0 10px;
}

@media (max-width: 490px) {
    .select-trip-group {
        display: block;
    }

    .start-date-field, .end-date-field {
        margin: 10px 0;
        width: '100%';
        display: block;
    }
}

.app-container,
.user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-container {
    height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
}

.user-info {
    margin-bottom: 20px;
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.user-name,
.card-header {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
}

.card-header {
    text-align: center;
    color: #343a40;
}

.info-table {
    font-size: 18px;
}

.info-table td {
    padding: 10px;
}

.info-table tr:hover {
    background-color: #f8f9fa;
}

.app-alert {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    animation: fadeIn 2s ease-in;
    margin-top: 100px;
}

.app-card {
    width: 100%;
    max-width: 600px;
    transition: transform 0.3s ease-in-out;
}

.app-card:hover {
    transform: scale(1.02);
}

.sticker {
    position: absolute;
    font-size: 2rem;
    opacity: 0;
    animation: clapping 3s linear infinite;
}
@media (max-width: 490px) {
    .app-container {
      padding-top: 300px; 
      padding-bottom: 230px;
    }

    .app-card-container{
        padding: 100px;
    }
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes clapping {
    0% { transform: translateY(0); opacity: 1; }
    50% { transform: translateY(-50vh); opacity: 1; }
    100% { transform: translateY(-100vh); opacity: 0; }
}