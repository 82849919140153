.basic-card {
    width: 18rem;
    margin: 1rem 2.5rem 1rem;
    background-color:  rgb(255, 254, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bio-basic-card {
    width: 18rem;
    margin: 5rem 2.5rem 1rem;
    background-color:  rgb(255, 254, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.basic-card.card .card-img, .bio-basic-card .card-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

@media only screen and (max-width: 600px){
    .basic-card {
        width: 100%!important;
        margin-right: 5px!important;
        margin-left: 5px!important;
    }

    .basic-card.card .card-img, .bio-basic-card .card-img {
        width: 90px;
        height: 90px;
    }
    .card-title {
        width: 95%;
        font-size: 0.85rem!important;
        text-align: center!important;
    }
    .card-body {
        padding: 5px 0.85rem!important;
    }
}
