.privacy-base {
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: 18px;
}

.privacy-body h6 {
    font-size: 18px;
}

.privacy-body {
    display: flex;
    flex-direction: column;
    margin: '0 auto';
    margin-top: 10vh;
    width: 80%;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
}

.privacy-title {
    margin-bottom: 1.3rem;
    font-weight: 400;
    font-size: 30px;
}

@media only screen and (max-width: 960px) {
    .privacy-base {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .privacy-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}

.privacy-base ul li {
    list-style-type: none;
}

.privacy-base ul li, 
.privacy-base p {
    text-align: justify;
}