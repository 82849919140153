.booking-modal {
    display: flex;
    flex-direction: column;
    /*background-color: #78a5d8;*/
}
.booking-modal-title {
    margin-bottom: 2.5rem;
}
.booking-modal-body {
    display: flex;
    justify-content: center;
    margin-top: -3.5rem;
}

.Modify-drivers-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -3.5rem;
}