.admin-base {
    display: flex;
    background-color: rgb(255, 254, 250);
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: auto;
    margin: 0;
}

.admin-body {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: 100vw;
    /* height: 100vh; */
    height: auto;
    margin-top: 10vh;
}

.admin-search-form {
    margin-top: 2rem;
    display: flex;
    width: 70vw;
    height: auto;
    margin-bottom: 32px;
}

.sub-header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: .6rem;
    color: darkgray;
}

.linear-display {
    display: flex;
    padding: 5rem;
    flex-direction: column;
}

.btn-confirm {
    margin-bottom: 20px;
}

/* BookingsTab.css */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }
  
  .pagination-container button {
    border: none;
    background: #007BFF;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .pagination-container button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-container button:not(:disabled):hover {
    background: #0056b3;
  }
  
  .pagination-container input,
  .pagination-container select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

.driverImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
}
.custom-btn {
    background-color: #0056b3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.custom-btn:hover {
    background-color: #004292; 
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.availability-toggle {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.toggle-container {
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: #eaeaea;
  border-radius: 12px;
  position: relative;
}

.toggle-track {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 12px;
  position: absolute;
  transition: background-color 0.3s ease-in-out;
}

.toggle-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out;
}

.toggle-thumb.active {
  transform: translateX(16px);
}

.toggle-track.active {
  background-color: #66bb6a;
}

.toggle-icon {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}



@media (max-width: 768px) {
  

 
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .pagination-container button,
  .pagination-container span {
    margin: 0.5rem;
  }
}

