.about-base {
    display: flex;
    flex-direction: column;
}

.about-body {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    width: 70vw;
    flex-wrap: wrap;
    color:rgb(59, 59, 59);
    text-align: justify;
}

.about-title {
    margin-bottom: 1.3rem;
    font-weight: 400;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .about-body {
        width: 100vw;
        margin-bottom: 12vh;
    }
}