.driverForm-container,
.DriverForm .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.DriverForm .driverForm-container {
    height: 100vh;
}

.DriverForm .user-info {
    margin-bottom: 20px;
}

.DriverForm  .user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.DriverForm .user-name,
.DriverForm .card-header {
    font-size: 20px;
    font-weight: bold;
}

.DriverForm .card-header {
    text-align: center;
}

.DriverForm .info-table {
    font-size: 18px;
}

.DriverForm .info-table td {
    padding: 10px;
}

.DriverForm .info-table tr:hover {
    background-color: #f8f9fa;
}

.DriverForm .app-alert {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    animation: fadeIn 2s ease-in;
}

.DriverForm .app-card {
    width: 100%;
    max-width: 600px;
    transition: transform 0.3s ease-in-out;
}

.DriverForm .app-card:hover {
    transform: scale(1.02);
}

.DriverForm .sticker {
    position: absolute;
    font-size: 2rem;
    opacity: 0;
    animation: clapping 3s linear infinite;
}

@media (max-height: 400px) {
    .DriverForm .driverForm-container {
        margin-top: 120px;
        margin-bottom: 50px;
    }
    
    .DriverForm .app-card {
        max-height: 30vh;
        overflow: auto;
    }
}

@media (min-height: 401px) and (max-height: 600px) {
    .DriverForm .driverForm-container {
        margin-top: 80px;
        margin-bottom: 30px;
    }
    
    .DriverForm .app-card {
        max-height: 50vh;
        overflow: auto;
    }
}

@media (min-height: 601px) and (max-height: 800px) {
    .DriverForm .driverForm-container {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    
    .DriverForm .app-card {
        max-height: 70vh;
        overflow: auto;
    }
}

@media (min-height: 801px) {
    .DriverForm .driverForm-container {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    
    .DriverForm .app-card {
        max-height: 90vh;
        overflow: auto;
    }
}


@media (max-width: 600px) {
    .DriverForm .app-card {
        max-width: 100%;
        margin: 10px;
    }

    .DriverForm .app-alert {
        padding: 10px;
    }

    .DriverForm .user-image {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .DriverForm .app-card {
        max-width: 80%;
        margin: 20px;
    }
}

@media (min-width: 901px) {
    .DriverForm .app-card {
        max-width: 600px;
        margin: 30px;
    }
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes clapping {
    0% { transform: translateY(0); opacity: 1; }
    50% { transform: translateY(-50vh); opacity: 1; }
    100% { transform: translateY(-100vh); opacity: 0; }
}
