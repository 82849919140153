/* .navbar-nav a  */
a.links.primaryColor,
a.links.whiteColor {
    padding: 0 20px;
    font-size: 18px;
    text-decoration: none;
}

.navbar-primary {
    /* background-color: #FF5F00; */
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.header span {
    color: white;
    font-size: 1.3rem;
}

.header .logo {
    /* background: white; */
    width: 200px;
    /* padding: 5px 20px; */
}

.footer .logo {
    width: 300px;
}

@media only screen and (max-width: 600px) {
    .header {
        justify-content: flex-start;
        background-color:#FF5F00 ;
    }
}

.me-auto {
    font-size: 1rem;
    font-weight: 50;
    color: white;
}
.links {
    font-weight: bold;
    font-size: 15px;
}

.primaryColor, span.primaryColor {
    color: #FF5F00;
}

.dropdown-toggle::after {
    color: white;
}

.dropdown-toggle.primaryColor::after{
    color: #c7c3bc
}

.whiteColor {
    color: #FFFFFF;
}

.links:hover {
    color: #FF5F00;
}

.base-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.toggle {
    display: flex;
}

.footer {
    color: #ffffff;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    width: 100%;
    background-color: #191919;
    flex-direction: column;
    padding: 4em 0 2em;
    /* margin-top: 4em; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.footer li a {
    color: white;
    text-decoration: none;
    font-weight: normal;
    margin: 1.5rem 0;
    display: block;
}

.footer-copyright a {
    color: #ff5f00;
    text-decoration: none;
}

button.navbar-toggler.collapsed {
    background: white;
}

.ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;
}

.ftco-footer-social li a {
    height: 50px;
    width: 50px;
    display: block;
    float: left;
    background: rgb(89 89 89);
    border-radius: 50%;
    position: relative;
    color: #fff;
}

.ftco-footer-social li a svg {
    position: absolute;
    font-size: 26px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.avatarImg {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 5px;
}

.nav-dropdown-menu.dropdown-menu.show {
    right: 0px;
    margin: 0.75rem;
    top: 58px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
}

@media only screen and (max-width: 992px) {
    .header-container, .footer-container {
        padding: 0 20px;
        /* margin: 0; */
        width: 100%;
        max-width: 100%;
    }

    div#responsive-navbar-nav {
        background: white;
        border-radius: 4px;
    }

    a.links.primaryColor, a.links.whiteColor {
        padding: 5px 8px;
    }

    .whiteColor {
        color: #FF5F00;
    }

    .footer .logo {
        width: auto;
        max-width: 100%;
    }
}


@media only screen and (max-width: 768px) {
    .footer .logo {
        width: auto;
        max-width: 300px;
    }
}

@media only screen and (max-width: 480px) {
    .footer .logo {
        width: auto;
        max-width: 100%;
    }
}
