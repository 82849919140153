@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.delete-account-container {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
}

.delete-account-container p {
  text-align: justify;
  line-height: 2;
  font-size: 15px;
}

  .login-button {
    background-color: #008CBA; /* Blue */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 12px;
  }
  
  .login-button:hover {
    background-color: white;
    color: black;
  }
  
  
  .success-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
  
  .success-popup p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .success-popup button {
    padding: 10px 20px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .success-popup button:hover {
    background-color: #0056b3;
  }
  
  .delete-account-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .delete-account-content {
    margin-bottom: 20px;
  }
  
  .delete-account-warning {
    margin-bottom: 10px;
  }
  
  .delete-account-checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .delete-account-checkbox {
    margin-right: 10px;
  }
  
  .delete-account-textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin-bottom: 10px;
  }
  
  .delete-account-button {
    padding: 10px 20px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-account-popup {
    margin-top: 20px;
  }
  
  .delete-account-popup-message {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .delete-account-popup-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .delete-account-popup-button {
    padding: 8px 16px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-account-form {
    margin-top: 20px;
  }
  
  .delete-account-form-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .delete-account-form-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .delete-account-form-button {
    padding: 10px 20px;
    background-color: #ff3d3d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .delete-account-password-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .delete-account-password-popup-content {
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  }
  
  .delete-account-password-popup-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
