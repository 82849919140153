.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    
  }
  
  .history-card {
    width: 70%;

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    border-radius: 0.25rem;
    margin: 2rem;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .history-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  .history-card-body {
    display: flex;
    flex-direction: column;
  }
  
  .history-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .history-card-subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .history-card-text {
    display: flex;
    justify-content: space-between;
  }
  
  .history-card-paid {
    color: green;
  }
  
  .history-card-notpaid {
    color: red;
    font-weight: bold;
  }
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .trip-type, .price, .history-card-paid, .history-card-notpaid, .distance, .duration {
    margin: 5px;
    font-family: 'Urbanist', sans-serif;

  }
  .table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  font-family: 'Urbanist', sans-serif;

}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #757575;
  padding: 0.75rem;
  text-transform: uppercase;
  color: #bdbdbd;
}

.table tbody + tbody {
  border-top: 2px solid #757575;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-hover tbody tr:hover {
  color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.075);
}

.driver-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #FF5F00;
}

  
  .driver-name {
    font-weight: bold;
    color: darkslategrey;
  }
  
  .driver-category {
    color: darkslategrey;
  }
  
  
  .card-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: darkslategrey;
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 1.5rem;
  }
  
  .btn-secondary {
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    color: #1b1b1b;
    margin-top: 1.5rem;
  }
